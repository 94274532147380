import React from "react"

import { CircleCheck } from "akar-icons"

import Button from "../common/Button"
import Link from "../common/Link"

import "./PasswordChanged.scss"

const PasswordChanged = () => {
  return (
    <div className="password-changed">
      <div className="password-changed__container">
        <div className="password-changed__tick-container">
          <CircleCheck size={72} color="#303546" />
        </div>
        <h3 className="password-changed__heading">Password Changed!</h3>
        <p className="password-changed__text">
          Your password has been changed successfully. Use your new password to
          login.
        </p>
        <Button color="primary" isBlock>
          <Link to="/login">Login Now</Link>
        </Button>
      </div>
    </div>
  )
}

export default PasswordChanged
