import React, { useEffect, useState } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import PasswordChanged from "../components/auth/PasswordChanged"
import SEO from "../components/seo.js"

const PasswordChangeSuccess = ({ location }) => {
  const [isMounted, setIsMounted] = useState(false)
  const email = !!location.state?.email

  useEffect(() => {
    if (!email) {
      navigate("/forgot-password")
      return
    }
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return <div></div>
  }

  return (
    <>
      <SEO
        title="Password Changed Successfully"
        path="/password-change-success/"
      />
      <PasswordChanged />
    </>
  )
}

PasswordChangeSuccess.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PasswordChangeSuccess
